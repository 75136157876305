import React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  {
    sanityWhoWeArePage {
      pageTitle
      collectiveBio {
        title
        _rawBody
      }
      quishile {
        image {
          asset {
            id
            originalFilename
            url
          }
          altText
        }
        _rawBody
      }
      esha {
        image {
          asset {
            id
            originalFilename
            url
          }
          altText
        }
        _rawBody
      }
    }
  }
`;

export default function WhoWeArePage({
  data: {
    sanityWhoWeArePage: { pageTitle, collectiveBio, quishile, esha }
  }
}) {
  return (
    <Layout title="Who We Are" page="who-we-are" url="/who-we-are">
      <h1 className="font-h1">{pageTitle}</h1>
      <h2 className="font-h2">{collectiveBio.title}</h2>
      <PortableText blocks={collectiveBio._rawBody} />

      <h2 className="font-h2">Individual Bios</h2>
      <div className="bio">
        <Image
          image={quishile.image}
          className="bio___image"
          objectFit="contain"
        />
        <PortableText blocks={quishile._rawBody} />
      </div>

      <div className="bio">
        <Image image={esha.image} className="bio___image" objectFit="contain" />
        <PortableText blocks={esha._rawBody} />
      </div>
    </Layout>
  );
}
